import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class ReviewStore {
    Token = null;

    loadReview= false;
    errloadReview = "";
    saved= false;
    nolist=false;
    Reviews=[];
    orderone={};

    orderid="";
    categoryname="";
    productname="";
    fromdate="";
    todate="";
    customername="";
    customeremail="";
    status="";

    loadProduct=false;
    errProductlist="";
    Productlist=[];

    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getReviews();
            this.getProductList()
            return
        }
    }

    setorderid = (text) =>  {
        this.orderid = text;
    }
    setcategory = (text) =>  {
        this.categoryname = text;
    }
    setproduct = (text) =>  {
        this.productname = text;
    }
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
        this.customeremail = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }

    // get reviews List
    getReviews = () => {
        runInAction(() => {
            this.loadReview = true;
            this.errloadReview = "";
            this.saved = false;
            });
        getMethodData("/admin/product-reviews",this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadReview = false;
                this.errloadReview = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadReview = false;
                this.errloadReview = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.Reviews = resp?.data?.data?.data;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadReview = false;
                this.errloadReview = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadReview = false;
                if(!error.response){
                    this.errloadReview = error?.message;
                    toast.error(error?.message);
                }
                this.errloadReview = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


        //filter reviews api 

        getFilterReviews = (page) => {
            runInAction(() => {
                this.loadReview = true;
                this.errloadReview = "";
                this.saved = false;
              });
            getMethodData(`/admin/product-reviews?page=${page}&order_number=${this.orderid}&customer=${this.customername}&email=${this.customeremail}&from_date=${this.fromdate}&to_date=${this.todate}&category_id=${this.categoryname}&product_name=${this.productname}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadReview = false;
                    this.errloadReview = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadReview = false;
                        this.errloadReview = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.Reviews = resp?.data?.data?.data;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadReview = false;
                  this.errloadReview = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadReview = false;
                    if(!error.response){
                        this.errloadReview = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadReview = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };


// get Category list
  getProductList = () => {
    runInAction(() => {
      this.loadProduct = true;
      this.errProductlist = "";
    });
    getMethodData(`/public/products`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadProduct = false;
            this.errProductlist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadProduct = false;
            this.errProductlist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.noProductlist = true;
            } else {
              this.noProductlist = false;
            }
            this.Productlist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadProduct = false;
          this.errProductlist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadProduct = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
};


export const ReviewStoreContext = createContext(new ReviewStore());
export const useReviewStore = () => useContext(ReviewStoreContext);