import {createContext,useContext} from 'react'
import { makeAutoObservable,observable, runInAction } from "mobx";
import {postLogin, getMethodData,patchMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class HomeStore {
    Token = null;


    loadDashboard=false
    errloadDashboard=""
    dashboarddata={}

    loaduser = true;
    errloaduser = "";
    userid = "";

    reportedstatus= ""

    loadReported = true;
    errloadReported = "";
  
    constructor(isloading = false){
        makeAutoObservable(this)
        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getDashboardCount()
          return;
        }
       
    }


    getstatusid = (text) => {
      this.userid = text;
    }

    setreportedstatus = (text) => {
      this.reportedstatus = text;
  }
  
// get dashboard list
  getDashboardCount = () => {
    runInAction(() => {
      this.loadDashboard = true
      this.errloadDashboard = ""
    });
    getMethodData("/admin/get-dashboard-data", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadDashboard = false
            this.errloadDashboard = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadDashboard = false
            this.errloadDashboard = resp?.data?.message
            this.dashboarddata = resp?.data?.data

          });
          return;
        }
        runInAction(() => {
          this.loadDashboard = false
          this.errloadDashboard = resp?.data?.message
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadDashboard = false;
          if (!error.response) {
            this.errloadDashboard = error?.message;
            toast.error(error?.message);
          }
          this.errloadDashboard = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        })
        return;
      });
  };


    //Active and inactive
statusChange = () => {
  runInAction(() => {
    this.loaduser = true;
    this.errloaduser = "";
  });
  getMethodData(`/admin/users/change-status/${this.userid}`,this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
          // toast.error(resp?.data?.message);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
          this.getDashboardCount();
          toast.success(resp?.data?.message);
        })
        return;
      }
      runInAction(() => {
        this.loaduser = false;
        this.errloaduser = resp?.data?.message;
      })
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.loaduser = false;
            this.errloaduser = error?.response?.data?.message;
      })
      return;
  })
};


// reported user status change

ChangeReportedStatus = (id) => {
  runInAction(() => {
    this.loadReported = true;
    this.errloadReported = "";
  });
  const formData = new FormData();
  formData.append("is_block", this.reportedstatus);

  postFormData(`/admin/users/block-unblock/${id}`,formData,this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadReported = false;
          this.errloadReported = resp?.data?.message;
          toast.error(resp.data?.data?.errors?.name[0]);
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
            this.loadReported = false;
            this.errloadReported = resp?.data?.message;
            toast.success(resp.data.message);
             this.getDashboardCount();
              // this.saved = true;
          })
          return
      }
      runInAction(() => {
        this.loadReported = false;
        this.errloadReported = resp?.data?.message;
        toast.error(resp.data.message);
      });
    })
    .catch((error) => {
      
      runInAction(() => {
          if(!error.response){
            this.loadReported = false;
            this.errloadReported = error?.message;
            toast.error(error?.message);
          }
            toast.error(error?.response?.data?.message);
            this.loadReported = false;
            this.errloadReported = error?.response?.data?.message;
      })
      return;
  })
};



};


export const HomeStoreContext = createContext(new HomeStore());
export const useHomeStore = () => useContext(HomeStoreContext);