import { observer } from 'mobx-react';
import React from 'react'
import { useUserStore } from './store/UserStore';
import { RxPencil1, RxPerson } from 'react-icons/rx';
import {Link, useNavigate} from "react-router-dom"
import { useEffect } from 'react';
import { useAuthStore } from '../Auth/store/Auth';
import Moment from 'react-moment';

const DeletedUser = observer(() => {

    const {
        loaduserdelete,
        indexPage,
        perPage,
        getstatusid,
        users,
        getUserDeletedFilter,
        getToken,
        nolistdelete,
      } = useUserStore();

      const { adminAuthApproved } = useAuthStore();


      useEffect(() => {
        if (adminAuthApproved) {
          getToken(localStorage.getItem("Admintoken"));
        }
      }, [adminAuthApproved]);
      
      useEffect(()=>{
        getUserDeletedFilter(1)    
        },[])

    return (
        <>
        <div  className='flex justify-between' >
        <div  className=" mb-4">
            <p className=" text-2xl font-semibold">Deleted Users</p>
            {/* <p className=" opacity-80 text-sm">Home / User</p> */}
          </div>
          <div>
          <button
             onClick={()=>{window?.history?.back()}}
            className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen"
          >
             Back
          </button>
        </div>
         </div>
         {/* <Filter  /> */}
        
        <div className="card bg-base-100 shadow-md p-6 mt-2">
         
            <div  className="overflow-x-auto w-full">
            <table id="tablescroll"  className="table table-zebra w-full">
             
                <thead  >           
                <tr>
                <th>S.no</th>
                {/* <th>Display Name</th> */}
                <th>Name</th>
                <th>Registration Date</th>
                {/* <th>Gender</th> */}
                <th>Mobile</th>
                {/* <th>State</th> */}
                {/* <th>City</th> */}
                {/* <th>Country</th> */}
                <th>Status</th>
              </tr>
                </thead>
                <tbody className="text-sm relative">
    
                {loaduserdelete && 
                  <>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="animate-pulse py-6"></td>
                  </tr>
                  </>
                  }
            
              
                
               { users?.map((data,index)=>(
                 
                 <tr key={index}>
                 <td>  {perPage * (indexPage - 1) + (index + 1)}</td>
                 {/* <td className="font-bold capitalize">{(data?.display_name??"-")}</td> */}
   
                 <td>
                   <div className="flex items-center space-x-3">
                     <div>
                       {data.is_deleted == 1 ? 
                       
                       <>
                       <div className="font-bold capitalize">{(data?.first_name??"-")}</div>
   
                          <div className="text-[red] capitalize"><Moment format="MM/DD/YYYY">{data.deleted_date ?? "N/A"}</Moment></div>
                       </>
                       
                       
                       : <>
                       <div className="font-bold capitalize">{(data?.first_name??"-")+" "+(data?.last_name??"-")}</div>
                     
                       <div className="capitalize text-xs">{data?.email}</div>
                       
                       </>  }
                      
                       </div>
                       </div>
                   </td>
                   {/* <td className=" capitalize">{data?.gender??"-"}</td> */}
                <td className="capitalize"><Moment format="MM/DD/YYYY">{data?.created_at}</Moment></td>
                   <td>{data?.phone_number??"-"}</td>
                   {/* <td>{data?.state?.name??"-"}</td> */}
                   {/* <td>{data?.city?.name??"-"}</td> */}
                   {/* <td className=" capitalize">{data?.country?.name??"-"}</td> */}
                   <th>
                     {data.is_deleted == 1 ?
                       <label
                       title="Change Status"
                       className={`btn capitalize ${ data.is_deleted == 1 && "bg-[red]"}  text-[#000] border-hidden  btn-xs`}
                       onClick={(e) => {
                         getstatusid(data?.id);
                       }}
                     >
                       {data.is_deleted == 1 && "Deleted" }
                     </label>
                     :
                     <label
                     title="Change Status"
                     htmlFor="my-modal-5"
                     className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                     onClick={(e) => {
                       getstatusid(data?.id);
                       // getNewstatus(data?.isActive);
                     }}
                   >
                     {   data.status ? "Active" : "Inactive"  }
                   </label>
                     }
                      
                     </th>
           
                 </tr>
                   
                ))}
              
               
                {nolistdelete && 
                  <tr>
                    <td colSpan={5} className="my-10 text-center">
                      <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                      <p className=" font-semibold text-lg">No Deleted User Found</p>
                      {/* <Link
                        to="./addcity"
                        className="btn btn-sm mt-4 bg-[#000] text-green text-sm hover:bg-[#000]"
                      >
                        <MdAdd />
                        Add City
                      </Link> */}
                    </td>
                  </tr>}
                </tbody>
            </table>
            </div>
        
        </div>
    
        </>
        
      )
});

export default DeletedUser

