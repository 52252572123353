import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class ReportedUserStore {
    Token = null;

    loadReported= false;
    errloadReported = "";
    saved= false;
    nolist=true;
    Reported=[];
    orderone={};

    reported_by="";
    reported_for="";
    
    fromdate="";
    todate="";

    customername="";
    customeremail="";

    status="";

    reportedstatus= ""

    constructor(isloading = false) {
        makeAutoObservable(this);
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
            this.getReported()
            return
        }
    }

    setreported_by = (text) =>  {
        this.reported_by = text;
    }
    setreported_for = (text) =>  {
        this.reported_for = text;
    }
    setfromdate = (text) =>  {
        this.fromdate = text;
    }
    settodate = (text) =>  {
        this.todate = text;
    }
    setcustomername = (text) =>  {
        this.customername = text;
    }
    setcustomeremail = (text) =>  {
        this.customeremail = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }
    setreportedstatus = (text) => {
        this.reportedstatus = text;
    }

    // // get Reported List
    getReported = () => {
        runInAction(() => {
            this.nolist= false
            this.Reported=[];
            this.loadReported = true;
            this.errloadReported = "";
            this.saved = false;
            });
        getMethodData("/admin/users/reported-users",this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                this.loadReported = false;
                this.errloadReported = resp?.data?.message
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                this.loadReported = false;
                this.errloadReported = resp?.data?.message;
                // console.log(resp?.data?.data.length);
                if(resp?.data?.data?.data?.length === 0) {
                    this.nolist = true;
                }else{
                    this.nolist = false;
                }
                // console.log(resp?.data?.data?.data)
                this.Reported = resp?.data?.data?.data;
                this.saved = false;
                // this.status = true;
                });
                return;
            }
            runInAction(() => {
                this.loadReported = false;
                this.errloadReported = resp?.data?.message;
            });
            })
            .catch((error) => {
            runInAction(() => {
                this.loadReported = false;
                if(!error.response){
                    this.errloadReported = error?.message;
                    toast.error(error?.message);
                }
                this.errloadReported = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


        //filter Reported api 

        getFilterReported = (page) => {
            runInAction(() => {
                this.loadReported = true;
                this.errloadReported = "";
                this.saved = false;
              });
            getMethodData(`/admin/users/reported-users?page=${page}&reported_by=${this.reported_by}&reported_for=${this.reported_for}&status=${this.status}&from_date=${this.fromdate}&to_date=${this.todate}`,this.Token)
            .then((resp) => {
      
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadReported = false;
                    this.errloadReported = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadReported = false;
                        this.errloadReported = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.Reported = resp?.data?.data?.data;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadReported = false;
                  this.errloadReported = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                    this.loadReported = false;
                    if(!error.response){
                        this.errloadReported = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadReported = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };


// reported user status change

        ChangeReportedStatus = (id) => {
            runInAction(() => {
              this.loadReported = true;
              this.errloadReported = "";
            });
            const formData = new FormData();
            formData.append("is_block", this.reportedstatus);
         
            postFormData(`/admin/users/block-unblock/${id}`,formData,this.Token)
              .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadReported = false;
                    this.errloadReported = resp?.data?.message;
                    toast.error(resp.data?.data?.errors?.name[0]);
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                  runInAction(() => {
                      this.loadReported = false;
                      this.errloadReported = resp?.data?.message;
                      toast.success(resp.data.message);
                       this.getReported();
                        // this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                  this.loadReported = false;
                  this.errloadReported = resp?.data?.message;
                  toast.error(resp.data.message);
                });
              })
              .catch((error) => {
                
                runInAction(() => {
                    if(!error.response){
                      this.loadReported = false;
                      this.errloadReported = error?.message;
                      toast.error(error?.message);
                    }
                      toast.error(error?.response?.data?.message);
                      this.loadReported = false;
                      this.errloadReported = error?.response?.data?.message;
                })
                return;
            })
          };
};


export const ReportedUserStoreContext = createContext(new ReportedUserStore());
export const useReportedUserStore = () => useContext(ReportedUserStoreContext);