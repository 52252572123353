import {createContext,useContext,useState} from 'react'
import { makeAutoObservable,useLocalObservable, observable, runInAction } from "mobx";
import {postMethodData,getMethodData,putMethod, patchMethodData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class SettingStore {

    Token = null;
    disabled = false;
    setting = [];
    id="";
    newfields=false;
    key="";
    value="";
    errloadsetting = "";
    loadsetting = false;
    editbtncheck= false;
    nolist = false;
    saved = false

 
    constructor(isloading = false) {
        makeAutoObservable(this);
        
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloading){
          this.getSetting();
          // this.gethomecourtdropdown();
          return
        }
        
     
      
      }




// button toggle
buttonCheck=()=>{
this.editbtncheck= true;
}
newcheck=()=>{
  this.editbtncheck=false;
}
disabledCheck=()=>{
  this.disabled= true;
}
disabledon=()=>{
  this.newfields =false;
}
// Get Key
Getkey=(text)=>{
  this.key=text;
}
getvalue=(text)=>{
  this.value= text;
}
getnewfeilds=()=>{
  this.newfields=true
}
    // get sett List
    getSetting = () => {
        runInAction(() => {
            this.loadsetting = true;
            this.errloadsetting = "";
            this.saved = false;
          });
        getMethodData("/admin/settings",this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadsetting = false;
                this.errloadsetting = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
              // console.log(resp?.data?.data)
              runInAction(() => {
                this.loadsetting = false;
                this.errloadsetting = resp?.data?.message;
                if(resp?.data?.data?.length === 0) {
                  this.nolist = true;
                }else{
                  this.nolist = false;
                }
                this.setting = resp?.data?.data;
                // this.id= resp?.data?.data?.data.id;
                this.saved = false;
                // this.status = true;
              });
              return;
            }
            runInAction(() => {
              this.loadsetting = false;
              this.errloadsetting = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loadsetting = false;
                if(!error.response){
                    this.errloadsetting = error?.message;
                    toast.error(error?.message);
                }
                this.errloadsetting = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };
    

    
  //update method
  updatesetting = () => {
    runInAction(() => {
      this.loadsetting = true;
      this.errloadsetting = "";
      this.saved = false;
    });
    putMethod(
      `/api/admin/setting`,
      {
        id: this.id,
        key: this.key,
        value: this.value
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addlevel = false;
            this.errloadsetting = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadsetting = false;
            this.errloadsetting = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.addloadhomecourt = false;
          this.errloadsetting = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloadhomecourt = false;
            this.errloadsetting = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.addloadhomecourt = false;
          this.errloadsetting = error?.response?.data?.message;
        });
        return;
      });
  };

};


export const UserSettingContext = createContext(new SettingStore());
export const useSettingStore = () => useContext(UserSettingContext);