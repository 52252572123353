import React from 'react';
import sanitizeHtml from "sanitize-html";
import { AES, enc } from "crypto-js";

const AdminMessageRender = ({message}) => {
    let decryptedMessage = message;
    try {
        decryptedMessage = AES.decrypt(message, process.env.REACT_APP_ENCRYPTION_KEY).toString(enc.Utf8) || message;
    } catch (error) {
        console.error("Decryption error: ", error);
        decryptedMessage = message;
    }
    return (
        <div className="text-sm message-body " style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={{ __html: decryptedMessage }} />
    )
}

export default AdminMessageRender;