import React, { useEffect, useState } from "react";
import { MdOutlineVpnKey } from "react-icons/md";
import { RxEnvelopeOpen, RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Logo from "../assets/images/logo.png"
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "./store/Auth";

const Login = observer(() => {
    const [showpassword,setshowpassword] = useState(false);
    const [confirmpassword,setconfirmpassword] = useState(false);
    const navigate = useNavigate();
    // const [Forgotui,setForgotui] = useState(false);
    const {
        loginadmin,
        emailChanged,passwordChanged,
        errLoggingIn,
        loginLoading,
        ForgotOTP,
        otpTimeStatus,
        otpscreen,
        passchangedsuccesfull,
        verifybtn,
        passwordConfirm,
        Forgotui,
        errPassword,
        getforgotpas,
        Forgotpassword,
        setforgotOTP,
        getemail,
        resetpass,
        Confirmpassw,
        otpForgotpassVerify,
        resendOtpForgotPass
    } = useAuthStore();
    
    const [Resend,setResend] = useState(false)
    const [timeLeft, setTimeLeft] = useState(20);
  
    useEffect(() => {
      if(Resend) {
        if(timeLeft > 0){
          const interval = setInterval(() => {
            setTimeLeft((prevCounter) => prevCounter - 1);
          }, 1000);
          return () => clearInterval(interval);
        } 
        else{
          setResend(false)
          setTimeLeft(0)
        }
      }
      if(passchangedsuccesfull){
        navigate("/")
        Forgotui(false)
      }
    },[Resend,timeLeft,passchangedsuccesfull])
  return (
    <div className="flex justify-center items-center w-full h-screen md:py-0 py-5 bg-[#ebebeb]">
      <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 bg-[#fff] shadow-md rounded-xl 2xl:w-6/12 lg:w-7/12 md:w-9/12 w-11/12 overflow-hidden">

        <div className=" bg-[#F6EB7A] p-5 lg:col-span-2 flex justify-center items-center">
            <div>
                <div className="">
                    <img className="w-[60%] mx-auto" alt="..." src={Logo}/>
                    {/* <p className="text-[#fff] font-bold text-xl text-center">Wokii</p> */}
                </div>
                <div className="text-[#fff] text-center mt-5">
                    <p className=" text-lg text-[#9c31a3] font-semibold">Sign In To Admin Panel</p>
                    {/* <p className=" text-sm mt-1">Sign In Your Account</p> */}
                </div>
            </div>
        </div>

        {!getforgotpas
        ?
        <div className="p-5 lg:col-span-3">
                <p className=" text-lg font-semibold">  Sign In to manage Tokee</p>
                <div className="mt-5">
                    <form onSubmit={(e) => {loginadmin();e.preventDefault()}}>
                    <div className="form-control">
                        <label className="label">
                        <span className="label-text font-semibold">Your Email</span>
                        </label>
                        <label className="input-group">
                        <span className=" bg-blue">
                            <RxEnvelopeOpen className=" text-[white]"/>
                        </span>
                        <input
                            autoComplete="username"
                            onChange={(e) => emailChanged(e.target.value)}
                            required
                            name="email"
                            type="email"
                            placeholder="Email"
                            className="input w-full border-[#000] focus:outline-none"
                        />
                        </label>
                    </div>
                    <div className="form-control mt-2">
                        <label className="label">
                        <span className="label-text font-semibold">Your Password</span>
                        </label>
                        <label className="input-group">
                        <span className=" bg-blue">
                        <MdOutlineVpnKey className=" text-[white]"/>
                        </span>
                        <input
                            autoComplete="new-password"
                            onChange={(e) => passwordChanged(e.target.value)}
                            required
                            name="password"
                            type={showpassword ? "text" : "password"}
                            placeholder="Password"
                            className="input w-full border-[#000] focus:outline-none border-r-0"
                        />
                        <span className=" bg-transparent cursor-pointer border-[#000] border border-l-0" onClick={() => setshowpassword(!showpassword)}>
                            {showpassword
                                ?
                                <RxEyeOpen className=" text-[#000]"/>
                                :
                                <RxEyeClosed className=" text-[#000]"/>
                            }
                        </span>
                        </label>
                    </div>
                        {!loginLoading
                        ?
                        <button type="submit" className="mt-5 btn w-full bg-blue hover:bg-darkgreen text-[#fff] font-semibold border-none">Sign In</button>
                        :
                        <button type="button" className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-blue hover:text-blue font-semibold border-none flex justify-center items-center">
                            <svg
                                className="animate-spin mr-1 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                >
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </button>
                        }
                        <label className="label">
                            <span className=" text-xs text-[#f33]">{errLoggingIn}</span>
                        </label>
                    </form>
                </div>
                <div className=" flex justify-center">
                    <p onClick={() => Forgotui(true)} className="cursor-pointer font-semibold text-xs underline">Forgot Password?</p>
                </div>
        </div>
        :

        <div className="p-5 lg:col-span-3">
            {!passwordConfirm ?<>
                {!otpscreen ?<>
                <p className=" text-lg font-semibold">Reset Password</p>
            <p className=" text-sm">
                Please enter your registered email address and we would send you the OTP to reset your password.
            </p>
            <div className="mt-5">
                <form autoComplete="off" onSubmit={(e) => {Forgotpassword();e.preventDefault()}}>
                    <div className="form-control">
                        <label className="label">
                        <span className="label-text font-semibold">Your Email</span>
                        </label>
                        <label className="input-group">
                        <span className=" bg-blue">
                            <RxEnvelopeOpen className=" text-lightblue"/>
                        </span>
                        <input
                            onChange={(e) => getemail(e.target.value)}
                            required
                            name="email"
                            type="email"
                            placeholder="Email"
                            className="input w-full border-[#000] focus:outline-none"
                        />
                        </label>
                    </div>
                    {!loginLoading
                    ?
                    <button type="submit" className="mt-5 btn w-full bg-blue hover:bg-darkgreen text-[#fff] font-semibold border-none">Get OTP</button>
                    :
                    <button type="button" className="mt-5 btn w-full bg-[#000] hover:bg-[#000] text-blue hover:text-blue font-semibold border-none flex justify-center items-center">
                        <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Generating OTP..
                    </button>
                    }
                    {/* <label className="label">
                        <span className=" text-xs text-[#f33]">{errLoggingIn}</span>
                    </label> */}
                </form>
            </div>
            </>:<>
            <form onSubmit={(e) => {otpForgotpassVerify();e.preventDefault();}}>
                          <div className=" text-center mb-10">
                            <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">Enter OTP</p>
                            <p className=" font-normal text-sm text-[#7E7777] my-7">We have sent a OTP (One Time Password) on your email address. Please enter that OTP here</p>
                          </div>
                          <div className=" flex items-center justify-center otp-inputs">
                          <OtpInput
                            value={ForgotOTP.forgototp}
                            onChange={setforgotOTP}
                            numInputs={4}
                          />
                          </div>
                          <p className=" text-center font-normal text-sm text-[#7E7777] my-8">
                          {!Resend
                          ?
                          <span className=" underline cursor-pointer" onClick={() => {resendOtpForgotPass();setResend(true)}}>Resend </span>
                          :
                          <span className=" opacity-60 underline cursor-pointer">Resend </span>
                          }
                          OTP {otpTimeStatus && <span className=" text-blue">in {timeLeft} Sec</span>} </p>
                          <label className="label text-center block">
                            {/* <span className=" text-xs text-[#f33]">{errSigningup}</span> */}
                          </label>
                          <div className="text-center mt-6">
                          {!loginLoading
                            ?
                            (
                              verifybtn
                              ?
                              <button className=" font-medium lg:text-[20px] text-lg text-blue bg-lightblue hover:bg-blue hover:text-lightblue px-11 py-2 rounded-lg mx-auto" type="submit">Verify</button>
                              :
                              <button type="button" disabled className=" opacity-50 font-medium lg:text-[20px] text-blue bg-lightblue hover:bg-blue hover:text-lightblue  px-11 py-2 rounded-lg mx-auto">Verify</button>
                            )
                            :
                            <button type="button" className="font-medium lg:text-[20px] text-lg text-[#000] bg-blue px-11 py-2 rounded-lg mx-auto flex justify-center items-center">
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Verifying..
                            </button>
                            }
                          </div>
                      </form>
            
            </>}
            </>:<>
            <form onSubmit={(e) => {resetpass();e.preventDefault();}}>
                      <div className=" text-center mb-10">
                        <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">Change Password</p>
                      </div>
                 
                      <label className=" relative">
                            <input
                            autoComplete="new-password"
                            required
                            onChange={(e) => passwordChanged(e.target.value)}
                            className="mt-5 bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                            placeholder="New Password"
                            type={showpassword ? "text" : "password"}
                            />
                            <span className=" cursor-pointer absolute right-4 top-1" onClick={() => setshowpassword(!showpassword)}>
                              {showpassword
                                  ?
                                  <RxEyeOpen className=" text-[#837575]"/>
                                  :
                                  <RxEyeClosed className=" text-[#837575]"/>
                              }
                            </span>
                          </label>


                  
                          <label className=" relative">
                            <input
                            autoComplete="confirm-password"
                            required
                            onChange={(e) => Confirmpassw(e.target.value)}
                            className="mt-5 bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                            placeholder="Confirm password"
                            type={confirmpassword ? "text" : "password"}
                            />
                            <span className=" cursor-pointer absolute right-4 top-1" onClick={() => setconfirmpassword(!confirmpassword)}>
                              {confirmpassword
                                  ?
                                  <RxEyeOpen className=" text-[#837575]"/>
                                  :
                                  <RxEyeClosed className=" text-[#837575]"/>
                              }
                            </span>
                          </label>
                      <label className="label">
                        <span className=" text-xs text-[#f33]">{errPassword}</span>
                      </label>
                      <div className="text-center mt-5">
                      <button className=" font-medium  lg:text-[20px] text-lg text-blue bg-lightblue hover:bg-blue hover:text-lightblue  px-11 py-2 rounded-lg mx-auto" type="submit">Update Password</button>
                      </div>
                  </form>

            </>}
         
         
            <div className=" flex justify-center mt-5">
                <p onClick={() => Forgotui(false)} className="cursor-pointer font-semibold text-xs underline">Cancel</p>
            </div>
        </div>
        }
        
      </div>
    </div>
  );
});

export default Login;