import { createContext, useContext } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import {
  getMethodData,
  putMethod,
  patchMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class SubscriptionStore {
  totalPremiumAmount = 0;
  Token = null;
  indexPage = 1;
  filtertrue = false;
  activePage = 1;
  Count = 1;
  perPage = "";
  filterVisibility = true;
  loadSubscriptions = false;
  loadSubscriptionOrders = false;
  errloadSubscriptions = "";
  saved = false;
  nolist = false;
  Subscriptions = [];
  SubscriptionOrders = [];
  orderone = {};
  nofilterwithpagination = false;
  subscriptionid = "";
  orderid = "";
  fromdate = "";
  todate = "";
  customername = "";
  customeremail = "";
  status = "";
  subscriptionmethod = "";
  paymentcycle = "";
  previousValues = {
    totalPremiumAmount: 0,
    subscription_id: "",
    order_number: "",
    email: "",
    from_date: "",
    to_date: "",
    paymentcycle: "",
    customer: "",
  };

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.subscription_id) {
      this.setSubscriptionId(isloading.subscription_id); // Set subscription ID here
    }
    if (isloading.isloading) {
      this.getSubscriptions(isloading.pagginationcount);
      return;
    }
    // if (isloading.editloading) {
    //   this.getOrderone(isloading?.id);
    // }
  }

  setTotalPremiumAmount = (text) => {
    this.totalPremiumAmount = text;
  };
  setsubscriptionid = (text) => {
    this.subscriptionid = text;
  };

  setorderid = (text) => {
    this.orderid = text;
  };
  setfromdate = (text) => {
    this.fromdate = text;
  };
  settodate = (text) => {
    this.todate = text;
  };
  setcustomername = (text) => {
    this.customername = text;
  };
  setcustomeremail = (text) => {
    this.customeremail = text;
  };
  setpaymentcycle = (text) => {
    this.paymentcycle = text;
  };

  setsubscriptionmethod = (text) => {
    this.subscriptionmethod = text;
  };

  setActivePage = (text) => {
    this.activePage = text;
  };
  setIndexPage = (e) => {
    this.indexPage = e;
  };
  setCount = (e) => {
    this.Count = e;
  };
  setPerPage = (e) => {
    this.perPage = e;
  };
  setFilterVisibility = (e) => {
    this.filterVisibility = e;
  };

  setfiltertrue = (e) => {
    this.filtertrue = e;
  };

  setSubscriptionId = (id) => {
    this.subscription_id = id;
  };

  NofilterwithPagination = (e) => {
    runInAction(() => {
      this.nofilterwithpagination = e;
    });
  };

  loadTableData = async (pageNumber) => {
    console.log("mee3")
    runInAction(() => {
      this.loadSubscriptions = true;
      this.errloadSubscriptions = "";
      this.Subscriptions = [];
      this.SubscriptionOrders = [];
      this.saved = false;
      this.setIndexPage(1);
    });

    try {
      const response = await getMethodData(
        `/admin/subscriptions?page=${pageNumber === 1 ? 1 : pageNumber}`,
        this.Token
      );
      if (response?.data?.status === false) {
        runInAction(() => {
          this.loadSubscriptions = false;
          this.errloadSubscriptions = response?.data?.message;
        });
        return;
      }
      if (response?.data?.status === true) {
        runInAction(() => {
          this.loadSubscriptions = false;
          this.errloadSubscriptions = response?.data?.message;
          // console.log(resp?.data?.data.length);
          if (response?.data?.data?.data?.length === 0) {
            this.nolist = true;
          } else {
            this.nolist = false;
          }

          this.setCount(response.data.data.total);
          this.setPerPage(response.data.data.per_page);
          this.setActivePage(response.data.data.current_page);
          this.Subscriptions = response?.data?.data?.data;
          this.setTotalPremiumAmount(response?.data?.total_premium_amount)
          this.filterVisibility = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.loadSubscriptions = false;
        console.error("Error loading table data:", error);
      });
    }
  };

  // get order List
  getSubscriptions = (page) => {
    console.log("mee1")
    runInAction(() => {
      this.loadSubscriptions = true;
      this.errloadSubscriptions = "";
      this.saved = false;
      this.indexPage = 1;
    });
    getMethodData(`/admin/subscriptions?page=${page}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadSubscriptions = false;
            this.errloadSubscriptions = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSubscriptions = false;
            this.errloadSubscriptions = resp?.data?.message;
            // console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.Subscriptions = resp?.data?.data?.data;
            this.totalPremiumAmount = resp?.data?.total_premium_amount;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.filterVisibility = true;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadSubscriptions = false;
          this.errloadSubscriptions = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSubscriptions = false;
          if (!error.response) {
            this.errloadSubscriptions = error?.message;
            toast.error(error?.message);
          }
          this.errloadSubscriptions = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get subscriptions order List
  getSubscriptionOrders = (page) => {
    runInAction(() => {
      this.loadSubscriptionOrders = true;
      this.errloadSubscriptions = "";
      this.saved = false;
      this.indexPage = 1;
    });
    // console.log("subscription_id", this.subscription_id);
    
    getMethodData(`/admin/subscriptions/transactons?page=${page}&subscription_id=${this.subscription_id}&from_date=&to_date=`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadSubscriptionOrders = false;
            this.errloadSubscriptions = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSubscriptionOrders = false;
            this.errloadSubscriptions = resp?.data?.message;
            // console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.SubscriptionOrders = resp?.data?.data?.data;
            this.totalPremiumAmount = resp?.data?.total_premium_amount;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.filterVisibility = true;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadSubscriptions = false;
          this.errloadSubscriptions = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSubscriptions = false;
          if (!error.response) {
            this.errloadSubscriptions = error?.message;
            toast.error(error?.message);
          }
          this.errloadSubscriptions = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //filter Order api

  getFilterSubscriptions = (page) => {
    console.log("mee2")
    runInAction(() => {
      this.loadSubscriptions = true;
      this.errloadSubscriptions = "";
      this.activePage = 1;
      this.indexPage = 1;
      this.saved = false;
      if (!this.nofilterwithpagination) {
        this.previousValues = {
          subscription_id: this.subscriptionid,
          order_number: this.orderid,
          email: this.customeremail,
          from_date: this.fromdate,
          to_date: this.todate,
          paymentcycle: this.paymentcycle,
          customer_name: this.customername,
          payment_method: this.subscriptionmethod,
        };
      }
    });
    if (this.nofilterwithpagination) {
      getMethodData(
        `/admin/subscriptions?page=${page}&subscription_id=${this.previousValues.subscription_id}&order_number=${this.previousValues?.order_number}&customer_name=${this.previousValues?.customer}&email=${this.previousValues?.email}&from_date=${this.previousValues?.from_date}&to_date=${this.previousValues?.to_date}&payment_cycle=${this.previousValues?.paymentcycle}`,
        this.Token
      ).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadSubscriptions = false;
            this.errloadSubscriptions = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSubscriptions = false;
            this.errloadSubscriptions = resp?.data?.message;
            // console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.Subscriptions = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.filterVisibility = true;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadSubscriptions = false;
          this.errloadSubscriptions = resp?.data?.message;
        });
      });
      return;
    }

    getMethodData(
      `/admin/subscriptions?page=${page}&subscription_id=${this.subscriptionid}&order_number=${this.orderid}&customer_name=${this.customername}&email=${this.customeremail}&from_date=${this.fromdate}&to_date=${this.todate}&payment_cycle=${this.paymentcycle}&payment_method=${this.subscriptionmethod}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadSubscriptions = false;
            this.errloadSubscriptions = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadSubscriptions = false;
            this.errloadSubscriptions = resp?.data?.message;
            // console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.Subscriptions = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.filterVisibility = true;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadSubscriptions = false;
          this.errloadSubscriptions = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadSubscriptions = false;
          if (!error.response) {
            this.errloadSubscriptions = error?.message;
            toast.error(error?.message);
          }
          this.errloadSubscriptions = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
}

export const SubscriptionStoreContext = createContext(new SubscriptionStore());
export const useSubscriptionStore = () => useContext(SubscriptionStoreContext);
