import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineClose,
  MdOutlineCheck,
  MdOutlineRefresh,
  MdOutlineSearch,
} from "react-icons/md";
import { Input, Button } from "react-daisyui";
import { observer } from "mobx-react";
import { useGroupStore } from "../store/GroupStore";

const Filter = observer(({emptyinput}) => {
  const {
    setGroupName,
    setOwnerName,
    setstatus,
    getFilterUsers,
    NofilterwithPagination,
    loadTableData,
    setfiltertrue,
    groupName,
    ownerName,
  } = useGroupStore();

  const Reset = () => {
    setGroupName("");
    setOwnerName("");
    setstatus("");
    loadTableData(1);
    setfiltertrue(false);
  };

  useEffect(()=> {
    setGroupName("");
    setOwnerName("");
    setstatus("");
    setfiltertrue(false);
},[emptyinput])

  return (
    <>
      <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
        <form
          onSubmit={(e) => {
            NofilterwithPagination(false);
            getFilterUsers(1);
            e.preventDefault();
          }}
        >
          <div className="grid lg:grid-cols-2 md:grid-cols-2 md:gap-8 gap-4 justify-end">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Group Name
              </label>
              <Input
                name="name"
                placeholder="Name"
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                value={groupName}
                id="name"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="owner"
                className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2"
              >
                Owner Name
              </label>
              <Input
                name="owner"
                placeholder="Owner"
                onChange={(e) => {
                  setOwnerName(e.target.value);
                }}
                value={ownerName}
                id="name"
                type="text"
                autoComplete="off"
                className="focus:outline-0 w-full text-sm font-medium rounded-md"
              />
            </div>
          </div>
          <div className="lg:mt-3 mt-2 flex items-center md:justify-end justify-between">
            <button
              type="reset"
              onClick={() => {
                getFilterUsers(1);
                Reset();
              }}
              className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "
            >
              <MdOutlineRefresh className="mr-1" />
              Reset
            </button>
            <button
              type="submit"
              className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
            >
              <MdOutlineSearch className="mr-1" />
              Search
            </button>
          </div>
        </form>
      </div>
    </>
  );
});

export default Filter;
