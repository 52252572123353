import { observer } from "mobx-react";
import { MdDoorbell, MdNotifications } from "react-icons/md";
import { NotificationsStore, NotificationsStoreContext, useNotificationsStore } from "./Store/NotificationStore";
import Moment from "react-moment";
import { useAuthStore } from "../Auth/store/Auth";
import { useEffect } from "react";


const NotificationList = () => {
    const store = new NotificationsStore({
      isloading: true,
    });
    return (
      <NotificationsStoreContext.Provider value={store}>
        <Screen />
      </NotificationsStoreContext.Provider>
   
    );
  };
  export default NotificationList;



const Screen = observer(() => {

    const {getNotificationCount,notificationcount} = useAuthStore()
    const {Notificationlist ,ClearNotificationList, getNotificationList} = useNotificationsStore()

    useEffect(() => {
        getNotificationCount()
        getNotificationList()
    },[notificationcount])

    return(
        <section className="h-auto overflow-hidden w-full ">
            <div className="flex justify-between mb-5">
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold">Notifications</p>
                </div>
                {Notificationlist.length != 0 && <button onClick={()=>ClearNotificationList()} className="btn btn-md bg-[#2f5542] text-[white] text-sm  ml-4">Clear All</button>}
            </div>
            <div className="card bg-base-100 shadow-md ">
                {Notificationlist && Notificationlist?.map((data,index) => (
                    <div key={index} className='flex hover:bg-[#f6cd62] rounded hover:font-bold cursor-default   items-start w-full py-3 border-b last:border-b-0 border-[#0000005f]'>
                        <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-7xl font-bold mx-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                        </svg>
                        </div>
                        <div className='flex items-center w-11/12 '>
                        <p className='text-[15px]'>{data?.body}</p>
                        </div>
                        <div className='flex items-start w-1/12 justify-end  mx-3'>
                        <p className='text-[11px]'><Moment fromNow>{data?.created_at ?? "N/A"}</Moment></p>
                        </div>
                    </div>
                ))}
                {!Notificationlist || (Notificationlist.length === 0 && <div className="text-white my-10 text-center font-bold col-span-4">No Notification Yet.</div>)}
            </div>
        </section>
    )
})