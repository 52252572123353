import { useState } from "react";
import { observer } from "mobx-react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  RxDashboard,
  RxEyeOpen,
  RxPencil1,
  RxPerson,
  RxPlus,
} from "react-icons/rx";
import {
  useGroupStore,
  GroupStore,
  GroupStoreContext,
} from "./store/GroupStore";
// import Filter from "./UserFilter/userfilter";
import Pagination from "react-js-pagination";
import { useEffect } from "react";
import Moment from "react-moment";
import Filter from "./GroupFilter/GroupFilter";
import { Checkbox } from "react-daisyui";
import { MdDeleteForever, MdHideSource } from "react-icons/md";

const GroupManagement = () => {
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || 1;

  const store = new GroupStore({
    isloading: true,
    editloading: false,
    pagginationcount: currentPage,
  });
  return (
    <GroupStoreContext.Provider value={store}>
      <Screen />
    </GroupStoreContext.Provider>
  );
};

export default GroupManagement;

const Screen = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [selectedGroupsList, setSelectedGroupsList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setActivePage(searchParamPage ?? 1);
    setIndexPage(searchParamPage ?? 1);
  }, [currentPage]);

  const [roomId, setRoomId] = useState("");
  const [trendingstatus, setTrendingstatus] = useState(false);
  const [isHide, setIsHide] = useState(null);
  const [channelId, setChannelId] = useState("");
  const [emptyinput,setemptyinput] = useState(false)
  const {
    users,
    loaduser,
    nolist,
    filterVisibility,
    activePage,
    perPage,
    Count,
    indexPage,
    filtertrue,
    getFilterUsers,
    loadTableData,
    getUsers,
    setIndexPage,
    setActivePage,
    statusChange,
    deleteGroup,
    deleteBulkGroup,
    hideBulkGroup,
    removeTrendingGroup,
    getstatusid,
    nofilterwithpagination,
    NofilterwithPagination,
    // setSelectedGroupsList,
    // selectedGroupsList = [],
  } = useGroupStore();

  useEffect(() => {
    setSelectedGroupsList([]);
    setSelectAll(false);
  }, [users]);

  /**
   * useEffect to get the category list with currrentPage from url params
   */
  useEffect(() => {
    if (searchParamPage) {
      const page = Number(searchParamPage ?? 1);
      setCurrentPage(page);
      setIndexPage(page);
      setSelectedGroupsList([]);
      setSelectAll(false);
    }
  }, [searchParamPage]);

  const handlePageChange = (pageNumber) => {
    NofilterwithPagination(true);
    const element = document.getElementById("tablescroll");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (filtertrue) {
      getFilterUsers(pageNumber);
      setIndexPage(pageNumber);
      setActivePage(pageNumber);
    } else {
      if (filterVisibility === true) {
        getUsers(pageNumber);
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      } else {
        setActivePage(pageNumber);
        setIndexPage(pageNumber);
      }
    }

    /**
     * if selected page is not 1 then navigate to the selected page adn ad dpageNumber to url
     */
    if (Number(pageNumber) + 1 !== 1) {
      navigate(`?page=${Number(pageNumber)}`);
      setActivePage(Number(pageNumber) + 1);
    } else {
      navigate("");
    }

    setSelectedGroupsList([]);
    setSelectAll(false);
    
  };
  //   useEffect(() => {
  //   //  loadTableData(1);
  //   //  getUsers();
  //  }, []);

  // console.log("users in grpmngmt", users);

  const handleCheckboxChange = (id) => {
    setSelectedGroupsList((prevList = []) => {
      if (prevList.includes(id)) {
        return prevList.filter((groupId) => groupId !== id);
      } else {
        return [...prevList, id];
      }
    });
  };

  const handleCheckboxChangeAll = () => {
    if (selectAll) {
      setSelectedGroupsList([]); // Deselect all
    } else {
      setSelectedGroupsList(users.map((user) => user.roomId._id)); // Select all
    }
    setSelectAll(!selectAll);
  };
  

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">
            Public Group Management
          </p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        {!!selectedGroupsList?.length && <div>
          <label
            title="Unhide Group"
            htmlFor="unhidebulk"
            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
          >
            <MdHideSource  className="mr-1" />
            {selectAll ? `Unhide All` : `Unhide`}
          </label>
          <label
            title="Hide Group"
            htmlFor="hidebulk"
            // onClick={() => {
              
            // }}
            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
          >
            <MdHideSource  className="mr-1" />
            {selectAll ? `Hide All` : `Hide`}
          </label>
          <label
            title="Delete Group"
            htmlFor="deletebulk-modal"
            // onClick={() => {
            
            // }}
            className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
          >
            <MdDeleteForever className="mr-1" />
            {selectAll ? `Delete All` : `Delete`}
          </label>
        </div>}
      </div>
      <Filter emptyinput={emptyinput}/>
      <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>
                <Checkbox
                    checked={selectAll}
                    onChange={handleCheckboxChangeAll}
                    className="checkbox"
                  />
                </th>
                <th>S.no</th>
                <th>Display Image</th>
                <th>Group Name</th>
                <th style={{ whiteSpace: "normal" }}>Owner</th>
                <th>Members Count</th>
                <th>Created On</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody id="tablescroll" className="text-sm relative">
              {loaduser && (
                <>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                  <tr>
                    <td colSpan={9} className="animate-pulse py-6"></td>
                  </tr>
                </>
              )}

              {!loaduser &&
                !nolist &&
                users &&
                users?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <Checkbox
                        checked={selectedGroupsList.includes(data?.roomId?._id)}
                        onChange={() => handleCheckboxChange(data?.roomId?._id)}
                        className="checkbox"
                      />
                    </td>
                    <td> {perPage * (indexPage - 1) + (index + 1)}</td>
                    <td className="font-bold capitalize ">
                      {data?.image ? (
                        <img
                          className="w-[50px] h-[50px] rounded-full bg-gray-200"
                          src={data?.image}
                          alt="Group"
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="font-bold capitalize">
                      {data?.name ?? "N/A"}
                    </td>
                    <td className=" capitalize">
                      <div className="whitespace-normal font-bold">
                        {data?.membersId?.owner?.name || "-"}
                      </div>
                      <p className="capitalize text-xs">
                        +{data?.membersId?.owner?.phone_number}
                      </p>
                    </td>
                    <td className="font-bold capitalize">
                      {data?.membersId?.membersCount || "-"}
                    </td>
                    <td className="capitalize">
                      <Moment format="MM/DD/YYYY">{data?.roomId?.createdAt}</Moment>
                    </td>
                    <td>
                      <div className="flex items-center space-x-4">
                        {data?.isHide == true ? (
                          <label
                            title="Change Status"
                            htmlFor="my-modal-5"
                            className={`btn capitalize ${
                              data?.isHide == 1 && "bg-warning"
                            } hover:bg-warning  text-[#000] border-hidden  btn-xs`}
                            onClick={(e) => {
                              setRoomId(data?.roomId?._id);
                              setIsHide(data?.isHide);
                              // getNewstatus(data?.isActive);
                            }}
                          >
                            {data?.isHide == true && "Unhide"}
                          </label>
                        ) : (
                          <label
                            title="Change Status"
                            htmlFor="my-modal-5"
                            className={`btn capitalize ${
                              !data?.isHide
                                ? "bg-green hover:bg-green"
                                : "bg-warning hover:bg-warning"
                            }  text-[#000] border-hidden mr-4 btn-xs`}
                            onClick={(e) => {
                              setRoomId(data?.roomId?._id);
                              setIsHide(data?.isHide);
                              // getNewstatus(data?.isActive);
                            }}
                          >
                            {data?.isHide == false && "Hide"}
                          </label>
                        )}
                        {/*<button className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000]  text-[#fff] border-hidden  btn-xs ml-2`}>Delete</button>*/}
                        <label
                          title="Delete Group"
                          htmlFor="delete-modal"
                          className={`btn capitalize bg-[#ff0000] hover:bg-[#ff0000] text-[#fff] border-hidden ml-2  btn-xs`}
                          onClick={(e) => {
                            setRoomId(data?.roomId?._id);
                            // getNewstatus(data?.isActive);
                          }}
                        >
                          Delete
                        </label>

                        <label
                          title="Remove Trending"
                          htmlFor="remove-trending"
                          className={`btn capitalize ${
                            data?.shouldTrending
                              ? "bg-[#ff0000] hover:bg-[#ff0000] text-[#fff]"
                              : "bg-warning hover:bg-warning text-[#000]"
                          }  border-hidden ml-2  btn-xs`}
                          onClick={(e) => {
                            setRoomId(data?.roomId?._id);
                            setTrendingstatus(!data?.shouldTrending);
                            // getNewstatus(data?.isActive);
                          }}
                        >
                          {data?.shouldTrending
                            ? "Remove Trending"
                            : "Add Trending"}
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}

              {nolist && (
                <tr>
                  <td colSpan={9} className="my-10 text-center">
                    <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                    <p className=" font-semibold text-lg">No Group Found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            activePage={activePage}
            containerClassName={"pagination"}
            itemsCountPerPage={Number(perPage)}
            totalItemsCount={Number(Count)}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />

          {/* status change model start */}
          <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={(e) => {
                    setemptyinput(!emptyinput);
                    statusChange(roomId, !isHide, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}

          {/* group delete model start */}
          <input type="checkbox" id="delete-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="delete-modal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Delete this group?
              </h3>
              <h4 className="text-[red] text-center">
                ("The owner's account will be deactivated as well.")
              </h4>
              <div className="modal-action justify-center">
                <label
                  htmlFor="delete-modal"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    setemptyinput(!emptyinput);
                    deleteGroup(roomId, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="delete-modal"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {/* group delete model end */}

          {/* group delete model start */}
          <input
            type="checkbox"
            id="remove-trending"
            className="modal-toggle"
          />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="remove-trending"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center px-6">
                {`Are you sure you want to ${trendingstatus ? `add this group to` : `remove this group from`} trending?`}
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="remove-trending"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    setemptyinput(!emptyinput);
                    removeTrendingGroup(roomId, trendingstatus, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="remove-trending"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>




          <input type="checkbox" id="deletebulk-modal" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="deletebulk-modal"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to Delete these groups?
              </h3>
              <h4 className="text-[red] text-center">
                ("The owner's account will be deactivated as well.")
              </h4>
              <div className="modal-action justify-center">
                <label
                  htmlFor="deletebulk-modal"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => {
                    setemptyinput(!emptyinput);
                    deleteBulkGroup(selectedGroupsList, currentPage);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="deletebulk-modal"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>


          <input type="checkbox" id="hidebulk" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="hidebulk"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="hidebulk"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={(e) => {
                    setemptyinput(!emptyinput);
                    hideBulkGroup(selectedGroupsList, currentPage,true);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="hidebulk"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>



          <input type="checkbox" id="unhidebulk" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="unhidebulk"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="unhidebulk"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={(e) => {
                    setemptyinput(!emptyinput);
                    hideBulkGroup(selectedGroupsList, currentPage,false);
                  }}
                >
                  Yes
                </label>
                <label
                  htmlFor="unhidebulk"
                  className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  "
                >
                  No
                </label>
              </div>
            </div>
          </div>

          
          {/* group delete model end */}
        </div>
      </div>
    </>
  );
});
