import { createContext, useContext } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import {
  getMethodData,
  putMethod,
  patchMethodData,
  postMethodData,
  postFormData,
  getChatMethodData,
  patchStatusChangeMethod,
  deleteGroupMethod,
  patchChangeMethod,
  getAdminChatMethodData,
  deleteAdminGroupMethod,
  postAdminChatMethod,
  hideAdminGroupMethod,
  hideAdminChannelMethod,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class PublicChannelStore {
  createdAt = "";
  image = "";
  isHide = "";
  membersId = "";
  channelId = "";
  room = "";
  name = "";
  owner = "";
  Token = null;
  indexPage = 1;
  activePage = 1;
  Count = 1;
  perPage = "";
  filterVisibility = true;
  users = [];
  errloaduser = "";
  loaduser = false;
  nolist = false;
  singleUser = {};
  filtertrue = false;
  addloaduser = false;
  erradduser = "";
  loaduserdelete = false;
  saved = false;

  first_name = "";
  Display_name = "";
  last_name = "";
  email = "";
  gender = "";
  address = "";
  street = "";
  postal_code = "";
  country = "";
  city = "";
  state = "";
  status = "";
  phone_number = "";
  nolistdelete = false;
  userid = "";

  loadCity = false;
  errCitylist = "";
  citylist = [];
  nocitylist = false;

  loadCountry = false;
  errCountrylist = "";
  Countrylist = [];
  nocountrylist = false;

  loadState = false;
  errStatelist = "";
  Statelist = [];
  noStatelist = false;

  devicetype = "";

  nofilterwithpagination = false;
  deleteuser = "deleted";
  previousValues = {
    first_name: "",
    Display_name: "",
    last_name: "",
    email: "",
    gender: "",
    phone_number: "",
    state: "",
    city: "",
    country: "",
    status: "",
    device_type: "",
  };

  groupName = "";
  ownerName = "";

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("Admintoken");
    if (isloading.isloading) {
      this.getUsers(isloading.pagginationcount);
      return;
    }
    if (isloading.editloading) {
      this.getuserone(isloading.id);
      return;
    }
  }
  setIndexPage = (e) => {
    this.indexPage = e;
  };
  setCount = (e) => {
    this.Count = e;
  };
  setPerPage = (e) => {
    this.perPage = e;
  };
  setFilterVisibility = (e) => {
    this.filterVisibility = e;
  };
  setFirst_name = (text) => {
    this.first_name = text;
  };
  setDisplay_name = (text) => {
    this.Display_name = text;
  };
  setlast_name = (text) => {
    this.last_name = text;
  };
  setphone_number = (text) => {
    this.phone_number = (text || "").toString();
  };
  setemail = (text) => {
    this.email = text;
  };
  setgender = (text) => {
    this.gender = text;
  };
  setaddress = (text) => {
    this.address = text;
  };
  setstreet = (text) => {
    this.street = text;
  };
  setcity = (text) => {
    this.city = text;
  };
  setstate = (text) => {
    this.state = text;
  };
  setpostal_code = (text) => {
    this.postal_code = text;
  };
  setcountry = (text) => {
    this.country = text;
  };
  setstatus = (text) => {
    this.status = text;
  };
  setdevicetype = (text) => {
    this.devicetype = text;
  };
  getToken = (e) => {
    this.Token = e;
  };
  getstatusid = (text) => {
    this.userid = text;
  };
  setActivePage = (text) => {
    this.activePage = text;
  };
  blankCityState = () => {
    this.citylist = [];
    this.Statelist = [];
  };
  setfiltertrue = (e) => {
    this.filtertrue = e;
  };

  setGroupName = (e) => {
    this.groupName = e;
  };

  setOwnerName = (e) => {
    this.ownerName = e;
  };

  //////////  this method is not being called in parent  //////////////

  loadTableData = async (pageNumber) => {
    runInAction(() => {
      this.loaduser = true;
      this.users = [];
      this.saved = false;
      this.setIndexPage(1);
    });

    try {
      const response = await getAdminChatMethodData(
        `/channel/public/get?page=${pageNumber}`
      );
      if (response?.data?.status === false) {
        runInAction(() => {
          this.loaduser = true;
          this.errloaduser = response?.data?.message;
        });
        return;
      }
      if (response?.data?.status === true) {
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = response?.data?.message;
          // console.log(resp?.data?.data.length);
          if (response?.data?.data?.data?.length === 0) {
            this.nolist = true;
          } else {
            this.nolist = false;
          }
          this.setCount(response.data.data.total);
          this.setPerPage(response.data.data.per_page);
          this.setActivePage(response.data.data.current_page);
          this.users = response?.data?.data?.data;
          this.filterVisibility = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.loaduser = false;
        console.error("Error loading table data:", error);
      });
    }
  };

  ////////////  get group users List  ////////////
  getUsers = (pageNumber) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
      this.users = [];
      this.indexPage = 1;
    });
    // getMethodData("/admin/users",this.Token)
    getAdminChatMethodData(`/channel/public/get?page=${pageNumber}`)
      .then((resp) => {
        // console.log("resp ststus???", resp?.data?.data);
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
             //console.log(resp?.data?.data.length);
            if (resp?.data?.data?.data?.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            // console.log(resp?.data?.data)
            this.users = resp?.data?.data?.data; //TODO:uncomment this once apis runs
            // console.log("users in store", this.users);
            this.Count = resp?.data?.data?.total;
            this.perPage = resp?.data?.data?.per_page;
            this.activePage = resp?.data?.data?.current_page;
            this.saved = false;
            this.filterVisibility = true;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  //get user edit data (show)

  getuserone = (id) => {
    getMethodData(`/admin/users/edit/${id}`, this.Token)
      .then((resp) => {
        runInAction(() => {
          this.loaduser = true;
          this.errloaduser = "";
        });
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp.data.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.singleUser = resp?.data?.data;
            this.first_name = resp?.data?.data?.first_name;
            this.Display_name = resp?.data?.data?.display_name;
            this.last_name = resp?.data?.data?.last_name;
            this.phone_number = (resp?.data?.data?.phone_number).toString();
            this.email = resp?.data?.data?.email;
            this.gender = resp?.data?.data?.gender;
            this.address = resp?.data?.data?.address;
            this.street = resp?.data?.data?.street;
            this.city = resp?.data?.data?.city_id;
            this.state = resp?.data?.data?.state_id;
            this.postal_code = resp?.data?.data?.postal_code;
            this.country = resp?.data?.data?.country_id;
            this.status = resp?.data?.data?.status;
            this.getCountryList();
            if (resp?.data?.data?.country_id) {
              this.getStateList(resp?.data?.data?.country_id);
            }
            if (resp?.data?.data?.state_id) {
              this.getCityList(resp?.data?.data?.state_id);
            }

            //   if(resp?.data?.data?.dob){
            //     this.dob =new Date(resp?.data?.data?.dob) ;
            //   }
            //   this.usermobile = ("+1"+resp?.data?.data?.mobile).toString();
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp.data.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  // user update

  edituser = (id) => {
    runInAction(() => {
      this.addloaduser = true;
      this.erradduser = "";
    });
    const formData = new FormData();
    formData.append("first_name", this.first_name);
    // formData.append("display_name", this.Display_name);
    formData.append("last_name", this.last_name);
    // formData.append("phone_number", this.phone_number.replace(/[^\d]/g, ""));
    // formData.append("email", this.email);
    formData.append("gender", this.gender);
    formData.append("address", this.address);
    formData.append("street", this.street);
    formData.append("city_id", this.city);
    formData.append("state_id", this.state);
    formData.append("postal_code", this.postal_code);
    formData.append("country_id", this.country);
    formData.append("status", this.status);
    postFormData(`/admin/users/update/${id}`, formData, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            // toast.error(resp.data.message);
            toast.error(resp.data?.data?.errors?.phone_number[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.success(resp.data.message);
            //  this.getUsers();
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloaduser = false;
            this.erradduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };

  ////////////// Active and inactive status change here /////////////////////
  statusChange = (room, isHide, currentPage) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });
    const data = {
      channelId: room,
      isHide: isHide,
    };
    postAdminChatMethod(`/channel/public/hide`, data)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(currentPage ?? 1);
            toast.success(resp?.data?.message);
          });
          this.setIndexPage(currentPage);
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  //////delete group

  deleteGroup = (roomId, roomname, currentPage) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });

    const data = {
      channelId: roomId,
      channelName: roomname,
    };
    deleteAdminGroupMethod(`/channel/public/delete`, data, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(currentPage ?? 1);
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  //////delete group in bulk

  deleteBulkGroup = (selectedGroupsList, currentPage) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });

    const data = {
      channels: selectedGroupsList
    };
    deleteAdminGroupMethod(`/channel/public/delete-multiple`, data, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(currentPage ?? 1);
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };
  //////hide group in bulk

  hideBulkGroup = (selectedGroupsList, currentPage,action) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });

    const channels = selectedGroupsList.map(groupId => ({
      channelId: groupId.channelId,
      isHide: action
    }));
    hideAdminChannelMethod(`/channel/public/hide-multiple`, channels, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(currentPage ?? 1);
            toast.success(resp?.data?.message);
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  removeTrendingGroup = (roomId, trendingstatus, currentPage) => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
    });

    const data = {
      channelId: roomId,
      status: trendingstatus,
    };
    patchChangeMethod(`/channel/trending/status`, data, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.getUsers(currentPage ?? 1);
            toast.success(resp?.data?.message);
          });
          this.setIndexPage(currentPage);
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.loaduser = false;
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
          this.loaduser = false;
          this.errloaduser = error?.response?.data?.message;
        });
        return;
      });
  };

  NofilterwithPagination = (e) => {
    runInAction(() => {
      this.nofilterwithpagination = e;
    });
  };

  //filter user api

  getFilterUsers = (page) => {
    runInAction(() => {
      this.activePage = 1;
      this.loaduser = true;
      this.indexPage = 1;
      this.errloaduser = "";
      this.saved = false;
      this.users = [];
      if (!this.nofilterwithpagination) {
        this.previousValues = {
          groupName: this.groupName,
          ownerName: this.ownerName,
        };
      }
    });
    if (this.nofilterwithpagination) {
      getAdminChatMethodData(
        `/channel/public/get?page=${page}&name=${this.previousValues.groupName}&ownerName=${this.previousValues.ownerName}`,
        this.Token
      ).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.loaduserdelete = true;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            if (resp?.data?.data?.data?.length == 0) {
              this.nolistdelete = true;
              this.nolist = true;
            } else {
              this.nolistdelete = false;
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.loaduserdelete = false;
            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      });
      return;
    }
    getAdminChatMethodData(
      `/channel/public/get?page=${page}&name=${this.previousValues.groupName}&ownerName=${this.previousValues.ownerName}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.loaduserdelete = true;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            if (resp?.data?.data?.data?.length == 0) {
              this.nolistdelete = true;
              this.nolist = true;
            } else {
              this.nolistdelete = false;
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.loaduserdelete = false;
            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  getUserDeletedFilter = (page) => {
    runInAction(() => {
      this.activePage = 1;
      this.loaduser = true;
      this.indexPage = 1;
      this.errloaduser = "";
      this.saved = false;
      this.users = [];
      this.loaduserdelete = true;
      this.nolistdelete = false;

      if (!this.nofilterwithpagination) {
        this.previousValues = {
          first_name: this.first_name,
          Display_name: this.Display_name,
          last_name: this.last_name,
          email: this.email,
          gender: this.gender,
          phone_number: this.phone_number,
          state: this.state,
          city: this.city,
          country: this.country,
          status: this.status,
          device_type: this.devicetype,
        };
      }
    });
    if (this.nofilterwithpagination) {
      getMethodData(
        `/admin/users?page=${page}&status=${this.deleteuser}`,
        this.Token
      ).then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.loaduserdelete = true;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            if (resp?.data?.data?.data?.length == 0) {
              this.nolistdelete = true;
              this.nolist = true;
            } else {
              this.nolistdelete = false;
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.loaduserdelete = false;
            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      });
      return;
    }
    getMethodData(
      `/admin/users?page=${page}&status=${this.deleteuser}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            this.loaduserdelete = true;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            // console.log(resp?.data?.data.length);

            if (resp?.data?.data?.data?.length == 0) {
              this.nolistdelete = true;
              this.nolist = true;
            } else {
              this.nolistdelete = false;
              this.nolist = false;
            }
            // console.log(resp?.data?.data?.data)
            this.filtertrue = true;
            this.loaduserdelete = false;

            this.users = resp?.data?.data?.data;
            this.Count = resp.data.data.total;
            this.perPage = resp.data.data.per_page;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          this.loaduserdelete = false;

          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // getUserDeletedFilter = (page) => {
  //   runInAction(() => {
  //     this.loaduserdelete = true;
  //     this.nolistdelete = false;
  //     this.errloaduserdelete = "";
  //     this.saved = false;
  //     this.Userdelete =[];
  //   });

  //   getMethodData(`/admin/users?page=${page}?filters[status]=${this.deleteuser}`, this.Token)
  //     .then((resp) => {
  //       if (resp?.data?.status === false) {
  //         runInAction(() => {
  //           this.loaduserdelete = false;
  //           this.errloaduserdelete = resp?.data?.message
  //         });
  //         return;
  //       }
  //       if (resp?.data?.status === true) {

  //         runInAction(() => {
  //           this.loaduserdelete = false;
  //           this.errloaduserdelete = resp?.data?.message;
  //           if (resp?.data?.data?.length == 0) {
  //             this.nolistdelete = true;
  //           } else {
  //             this.nolistdelete = false;
  //           }
  //           this.Userdelete = resp?.data?.data;
  //           this.saved = false;
  //           this.status = true;
  //         });
  //         return;
  //       }
  //       runInAction(() => {
  //         this.loaduserdelete = false;
  //         this.errloaduserdelete = resp?.data?.message;
  //       });
  //     })
  //     .catch((error) => {
  //       runInAction(() => {
  //         this.loaduserdelete = false;
  //         if (!error.response) {
  //           this.errloaduserdelete = error?.message;
  //           toast.error(error?.message);
  //         }
  //         this.errloaduserdelete = error?.response?.data?.message;
  //         toast.error(error?.response?.data?.message);
  //       })
  //       return;
  //     });
  // };

  // get country list
  getCountryList = () => {
    runInAction(() => {
      this.loadCountry = true;
      this.errCountrylist = "";
      this.Countrylist = [];
    });
    getMethodData("/public/countries", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCountry = false;
            this.errCountrylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCountry = false;
            this.errCountrylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nocountrylist = true;
            } else {
              this.nocountrylist = false;
            }
            this.Countrylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCountry = false;
          this.errCountrylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCountry = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get State list
  getStateList = (id, cityyes) => {
    runInAction(() => {
      this.loadState = true;
      this.errStatelist = "";
    });
    getMethodData(`/public/states?country_id=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadState = false;
            this.errStatelist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadState = false;
            this.errStatelist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.noStatelist = true;
            } else {
              this.noStatelist = false;
            }
            this.Statelist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadState = false;
          this.errStatelist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadState = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };

  // get city list
  getCityList = (id) => {
    runInAction(() => {
      this.loadCity = true;
      this.errCitylist = "";
    });
    getMethodData(`/public/cities?state_id=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCity = false;
            this.errCitylist = "";
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCity = false;
            this.errCitylist = resp?.data?.message;
            if (resp?.data?.data?.length === 0) {
              this.nocitylist = true;
            } else {
              this.nocitylist = false;
            }
            this.citylist = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadCity = false;
          this.errCitylist = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadCity = false;
          if (!error.response) {
            toast.error(error?.message);
          }
          toast.error(error?.response?.data?.message);
        });
        return;
      });
  };
}

export const PublicChannelStoreContext = createContext(
  new PublicChannelStore()
);
export const usePublicChannelStore = () =>
  useContext(PublicChannelStoreContext);
