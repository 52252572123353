import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch } from "react-icons/md";
import { Input, Button } from "react-daisyui"
// import { usePaymentStore, PaymentStore, PaymentStoreContext } from "../store/PaymentStore";
import { useContactStoreStore } from "../Store/Contact";
import { observer } from "mobx-react";

// const Filter = observer(() => {
//     const store = new PaymentStore({
//         isloading:true,
//       });
//     return (
//         <PaymentStoreContext.Provider value={store}>
//             <Screen />
//         </PaymentStoreContext.Provider>
//     )
// })

// export default Filter;

const Filter = observer(({emptyinput}) => {

    const {
        getFilterContact,
        setcustomername,
        setcustomeremail,
        setcustomerphone,
        getcontactList,
        setstatus,
        settodate,
        setfromdate,
        setticket
    }
    = useContactStoreStore()

    const Reset=()=>{
        setcustomername("")
        setcustomerphone("")
        setcustomeremail("")
        setfromdate("")
        setstatus("")
        settodate("")
        setticket("")
    }

    useEffect(()=> {
        setcustomername("")
        setcustomerphone("")
        setcustomeremail("")
        setfromdate("")
        setstatus("")
        settodate("")
        setticket("")
      },[emptyinput])

    return (
        <>

            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => {getFilterContact();e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                    <div>
                            <label htmlFor="CustomerName" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Name</label>
                            <Input
                                name="CustomerName"
                                placeholder="Customer Name"
                                id="CustomerName"
                                onChange={(e)=> {setcustomername(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="Ticket ID" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Ticket ID</label>
                            <Input
                                name="Ticket ID"
                                placeholder="Ticket ID"
                                id="Ticket ID"
                                onChange={(e)=> {setticket(e.target.value)}}
                                min={"0"}
                                type="number"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="CustomerEmail" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Email</label>
                            <Input
                                name="CustomerEmail"
                                placeholder="Customer Email"
                                id="CustomerEmail"
                                onChange={(e)=> {setcustomeremail(e.target.value)}}
                                type="text"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="CustomerEmail" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Customer Phone No.</label>
                            <Input
                                name="CustomerEmail"
                                placeholder="Customer Phone No."
                                id="CustomerEmail"
                                onChange={(e)=> {setcustomerphone(e.target.value)}}
                                type="number"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        
                        <div>
                            <label htmlFor="fromDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">From Date</label>
                            <Input
                                name="fromDate"
                                placeholder=" From Date"
                                id="fromDate"
                                onChange={(e)=> {setfromdate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="toDate" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">To Date</label>
                            <Input
                                name="toDate"
                                placeholder=" To Date"
                                id="toDate"
                                onChange={(e)=> {settodate(e.target.value)}}
                                type="date"
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>
                     
                    
                    </div>
                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <button type="reset"
                         onClick={() => {getcontactList();Reset()  }}
                          className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineRefresh className="mr-1" />Reset</button>
                        <button  type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"><MdOutlineSearch className="mr-1" />Search</button>
                    </div>
                </form>
            </div>

        </>
    )
})
export default Filter;