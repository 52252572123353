import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { useReviewStore,ReviewStore,ReviewStoreContext } from "./store/ReviewStore";
import Filter from "./ReviewsFilter/reviewsfilter";
import { MdAdd, MdOutlineRateReview } from "react-icons/md";
import { useState } from "react";

const ReviewManagement = () => {
    const store = new ReviewStore({
      isloading:true,
    });
    return(
      <ReviewStoreContext.Provider value={store}>
        <Screen/>
      </ReviewStoreContext.Provider>
    )
  }
  
  export default ReviewManagement;

  const Screen = observer(()=>{

    const {
      Reviews,
      loadReview,
      nolist
    }= useReviewStore()
    const [emptyinput,setemptyinput] = useState(false)
    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">Review Management</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        
       </div>
       <Filter emptyinput={emptyinput}/>
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Customer Name</th>
                <th>Review Date</th>
                <th>Rating Date</th>
                <th>Review Message</th>
                {/* <th>Status</th> */}
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadReview && 
              <>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={5} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

              
            {
                Reviews && Reviews.map((res,index) => (
                <tr key={index}>
                <td>{index+1}</td>
                <td className=" capitalize">#{res?.order?.order_number}</td>
                <td className=" capitalize">{res?.product?.name}</td>
                <td className=" capitalize">{res?.product?.category?.name}</td>
                <td className=" capitalize"><span className=" font-bold">{res?.customer?.first_name}</span> <p className="capitalize text-xs">{res?.customer?.email}</p></td>
                <td className=" capitalize"><Moment format="MM/DD/YYYY">{res.review_time ?? "N/A"}</Moment></td>
                <td className=" capitalize">
                  <div className="tooltip"> {(res.review).substring(0, 50).concat('...') ?? "N/A"}
                    <span className="tooltiptext bg-[#000]" > {res.review}</span>
                  </div>
                </td>
                {/* <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${true ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                      }}
                    >
                      {true ? "Succesful" : "Pending"}
                    </label>
                  </th> */}
                {/* <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      <button title="Edit">
                        <Link to={`/Reviewmanagement/${1}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td> */}
                </tr>
                 ))
                }

            {nolist && 
              <tr>
                <td colSpan={7} className="my-10 text-center">
                  <MdOutlineRateReview className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Review Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] ml-4"
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-[#000] text-green text-sm hover:bg-[#000]">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })