import {createContext,useContext} from 'react'
import { makeAutoObservable, runInAction } from "mobx";
import {getMethodData,putMethod, patchMethodData, postFormData} from "../../ApiMethods/api"
import { toast } from "react-toastify";

export class CategoryStore {
    Token = null;
    indexPage=1;
    activePage=1;
    checkbox = 0;
    Count=1;
    perPage="";
    filterVisibility=true;
    categorys = [];
    loadCategory = false;
    errloadCategory = "";
    nolist = false;
    filtertrue=false;
    singleCategory;
    nofilterwithpagination= false;
    addloadcategory= false;
    addedloadcategory= false;
    erraddcategory="";
    name= "";
    keywords="";
    status="";
    image="";
    imageurl="";
    ProductType="";
    price= "";
    categoryid= "";

    saved = false

    loadProducttype=false;
    errProducttypelist = "";
    noProducttypelist=true;
    Producttypelist=[]
    previousValues = {
      name: "",
      status:"",
      type_id:""
    };

    constructor(isloading = false) {
        makeAutoObservable(this);
       
        this.Token = localStorage.getItem("Admintoken");
        if(isloading.isloadingtype){
          this.getProducttypeList()
        }
        if(isloading.isloading){
            this.getCategorys(isloading.pagginationcount);
            return
        }
        if(isloading.editloading){
            this.getCategoryone(isloading.id);
            this.getProducttypeList()
            return
        }
    }

    setname = (text) =>  {
        this.name = text;
    }
    setProductType = (text) =>  {
      this.ProductType = text;
  }
    setkeywords = (text) =>  {
        this.keywords = text;
    }
    setstatus = (text) =>  {
        this.status = text;
    }
    setimage = (text) =>  {
        this.image = text;
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setprice = (text) =>  {
      this.price = text;
  }

    getstatusid = (text) => {
        this.categoryid = text;
    }
    setActivePage = (text) =>{
      this.activePage = text;
    }
    setIndexPage=(e)=>{
      this.indexPage=e;
    }
    setCount=(e)=>{
      this.Count=e;
    }
    setPerPage=(e)=>{
      this.perPage=e;
    }
    setFilterVisibility=(e)=>{
      this.filterVisibility=e;
    }
    setcheckbox=(text)=>{
      this.checkbox =text
  
    }
    NofilterwithPagination = (e) => {
      runInAction(() => {
          this.nofilterwithpagination = e
      })
    }

           // get product type list
           getProducttypeList = () => {
            runInAction(() => {
              this.loadProducttype = true;
              this.errProducttypelist = "";
            });
            getMethodData(`/admin/product-types`, this.Token)
              .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadProducttype = false;
                    this.errProducttypelist = "";
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                  runInAction(() => {
                    this.loadProducttype = false;
                    this.errProducttypelist = resp?.data?.message;
                    if (resp?.data?.data?.length === 0) {
                      this.noProducttypelist = true;
                    } else {
                      this.noProducttypelist = false;
                    }
                    this.Producttypelist = resp?.data?.data;
                  });
                  return;
                }
                runInAction(() => {
                  this.loadProducttype = false;
                  this.errProducttypelist = resp?.data?.message;
                });
              })
              .catch((error) => {
                runInAction(() => {
                  this.loadProducttype = false;
                  if (!error.response) {
                    toast.error(error?.message);
                  }
                  toast.error(error?.response?.data?.message);
                });
                return;
              });
          };

        // get category List
        getCategorys = (pageNumber) => {
            runInAction(() => {
                this.loadCategory = true;
                this.errloadCategory = "";
                this.saved = false;
                this.categorys=[];
                this.indexPage = 1;
                });
            // getMethodData("/admin/categories",this.Token)
            
            getMethodData(`/admin/categories?page=${pageNumber}`,this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                    this.loadCategory = false;
                    this.errloadCategory = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                    this.loadCategory = false;
                    this.errloadCategory = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.categorys = resp?.data?.data?.data;
                    this.Count = resp?.data?.data?.total;
                    this.perPage = resp?.data?.data?.per_page;
                    this.activePage = resp?.data?.data?.current_page;
                    this.saved = false;
                    this.filterVisibility = true;
                    // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadCategory = false;
                    this.errloadCategory = resp?.data?.message;
                });
                })
                .catch((error) => {
                runInAction(() => {
                    this.loadCategory = false;
                    if(!error.response){
                        this.errloadCategory = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadCategory = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
        };
        loadTableData = async (pageNumber) => {
          runInAction(() => {
            this.loadCategory = true;
            this.errloadCategory = "";
            this.saved = false;
            this.setIndexPage(1);
            });
          try {
            const response = await getMethodData(`/admin/categories?page=${pageNumber === 1 ? 1 : pageNumber}`,this.Token)
            if (response?.data?.status === false) {
              runInAction(() => {
              this.loadCategory = false;
              this.errloadCategory = response?.data?.message
              });
              return;
          }
          if (response?.data?.status === true) {
            runInAction(() => {
              this.loadCategory = false;
              this.errloadCategory = response?.data?.message;
              // console.log(resp?.data?.data.length);
              if(response?.data?.data?.data?.length === 0) {
                  this.nolist = true;
              }else{
                  this.nolist = false;
              }
                this.setCount(response.data.data.total);
                this.setPerPage(response.data.data.per_page);
                this.setActivePage(response.data.data.current_page)
                this.categorys = response?.data?.data?.data;
                this.filterVisibility = true;
    });
          }
    
        
      
          } catch (error) {
            runInAction(() => {
              this.loadCategory = false;
              console.error("Error loading table data:", error);
          });
          }
        };
            //get category edit data (show)

    getCategoryone =(id) => {
        getMethodData( `/admin/categories/edit/${id}`, this.Token)
        .then((resp) => {
            runInAction(() => {
                this.loadCategory = true;
                this.errloadCategory = "";
              });
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadCategory = false;
              this.errloadCategory = resp.data.message;
              // toast.error(resp.data.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
                this.loadCategory = false;
                this.singleCategory =  resp?.data?.data
                this.ProductType = resp?.data?.data?.type_id
                this.price = resp?.data?.data?.price
                this.checkbox= resp?.data?.data?.is_popular
                this.name = resp?.data?.data?.name
                this.keywords = resp?.data?.data?.keywords?.join(",").replace(/\s/g, '')
                this.status = resp?.data?.data?.status
                this.image = resp?.data?.data?.image
                this.imageurl = resp?.data?.data?.image
            })
            return;
          }
          runInAction(() => {
            this.loadCategory = false;
            this.errloadCategory = resp.data.message;
            toast.error(resp.data.message);
          });
        })
        .catch((error) => {
          runInAction(() => {
              if(!error.response){
                this.loadCategory = false;
                this.errloadCategory = error?.message;
                toast.error(error?.message);
              }
                toast.error(error?.response?.data?.message);
                this.loadCategory = false;
                this.errloadCategory = error?.response?.data?.message;
          })
          return;
      })
    }


         // category add

 addcategory = () => {
    runInAction(() => {
      this.addedloadcategory = true;
      this.erraddcategory = "";
    });
    const formData = new FormData();
    formData.append("type_id", this.ProductType);
    formData.append("name", this.name);
    formData.append("is_popular", this.checkbox);
    formData.append("keywords",  JSON.stringify(this.keywords.split(',')));
    formData.append("price",this.ProductType === "3" || this.ProductType === "4"  ? this.price : "0");
    formData.append("status", this.status);
    formData.append("image", this.image[0]);
    postFormData(`/admin/categories/store`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addedloadcategory = false;
            this.erraddcategory = resp?.data?.message;
            toast.error(resp.data?.data?.errors?.name[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addedloadcategory = false;
              this.erraddcategory = resp?.data?.message;
              toast.success(resp.data.message);
              //  this.getCategorys();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addedloadcategory = false;
          this.erraddcategory = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        
        runInAction(() => {
            if(!error.response){
                
              this.addedloadcategory = false;
              this.erraddcategory = error?.message;
              toast.error(error?.message);
            }
                
              toast.error(error?.response?.data?.message);
              this.addedloadcategory = false;
              this.erraddcategory = error?.response?.data?.message;
        })
        return;
    })
  };

  editcategory = (id) => {
    runInAction(() => {
      this.addloadcategory = true;
      this.erraddcategory = "";
    });
    const formData = new FormData();
    formData.append("type_id", this.ProductType);
    formData.append("name", this.name);
    formData.append("is_popular", this.checkbox);
    formData.append("keywords",  JSON.stringify(this.keywords.split(',')));
    formData.append("price", this.price);
    formData.append("status", this.status);
    if (this.image && this.image.length > 0) {
        formData.append("image", this.image[0]);
      } else {
        formData.delete("image");
    }
    postFormData(`/admin/categories/update/${id}`,formData,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloadcategory = false;
            this.erraddcategory = resp?.data?.message;
            toast.error(resp.data?.data?.errors?.name[0]);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
              this.addloadcategory = false;
              this.erraddcategory = resp?.data?.message;
              toast.success(resp.data.message);
              //  this.getCategorys();
              this.saved = true;
            })
            return
        }
        runInAction(() => {
          this.addloadcategory = false;
          this.erraddcategory = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        
        runInAction(() => {
            if(!error.response){
              this.addloadcategory = false;
              this.erraddcategory = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.addloadcategory = false;
              this.erraddcategory = error?.response?.data?.message;
        })
        return;
    })
  };


    //Active and inactive
statusChange = (currentPage) => {
    runInAction(() => {
      this.loadCategory = true;
      this.errloadCategory = "";
      this.categorys=[];
    });
    getMethodData(`/admin/categories/change-status/${this.categoryid}`,this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadCategory = false;
            this.errloadCategory = resp?.data?.message;
            // toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadCategory = false;
            this.errloadCategory = resp?.data?.message;
            this.getCategorys(currentPage ?? 1);
            toast.success(resp?.data?.message);
          })
          this.setIndexPage(currentPage);
          return;
        }
        runInAction(() => {
          this.loadCategory = false;
          this.errloadCategory = resp?.data?.message;
        })
      })
      .catch((error) => {
        runInAction(() => {
            if(!error.response){
              this.loadCategory = false;
              this.errloadCategory = error?.message;
              toast.error(error?.message);
            }
              toast.error(error?.response?.data?.message);
              this.loadCategory = false;
              this.errloadCategory = error?.response?.data?.message;
        })
        return;
    })
  };
setfiltertrue=(e)=>{
  this.filtertrue = e;
}

     //filter user api 

     getFilterCategory = (page) => {
        runInAction(() => {
            this.activePage = 1;
            this.loadCategory = true;
            this.indexPage = 1;
            this.filtertrue=false;
            this.errloadCategory = "";
            this.saved = false;
            this.categorys=[];
            if(!this.nofilterwithpagination){
              this.previousValues = {
                name: this.name,
                status: this.status,
                type_id: this.ProductType,
              };
            }
          });
      
          if(this.nofilterwithpagination){
            getMethodData(`/admin/categories?page=${page}&name=${ this.previousValues?.name}&status=${ this.previousValues?.status}&type_id=${ this.previousValues?.type_id}`,this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                  runInAction(() => {
                    this.loadCategory = false;
                    this.errloadCategory = resp?.data?.message
                  });
                  return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.loadCategory = false;
                        this.setfiltertrue(true);
                        this.errloadCategory = resp?.data?.message;
                        // console.log(resp?.data?.data.length);
                        if(resp?.data?.data?.data?.length === 0) {
                            this.nolist = true;
                        }else{
                            this.nolist = false;
                        }
                        // console.log(resp?.data?.data?.data)
                        this.categorys = resp?.data?.data?.data;
                        this.Count = resp.data.data.total;
                        this.perPage = resp.data.data.per_page;
                        this.saved = false;
                        // this.status = true;
                        });
                  return;
                }
                runInAction(() => {
                  this.loadCategory = false;
                  this.errloadCategory = resp?.data?.message;
                });
              })
              return;
            
          }
        getMethodData(`/admin/categories?page=${page}&name=${this.name}&status=${this.status}&type_id=${this.ProductType}`,this.Token)
        .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.loadCategory = false;
                this.errloadCategory = resp?.data?.message
              });
              return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.loadCategory = false;
                    this.setfiltertrue(true);
                    this.errloadCategory = resp?.data?.message;
                    // console.log(resp?.data?.data.length);
                    if(resp?.data?.data?.data?.length === 0) {
                        this.nolist = true;
                    }else{
                        this.nolist = false;
                    }
                    // console.log(resp?.data?.data?.data)
                    this.categorys = resp?.data?.data?.data;
                    this.Count = resp.data.data.total;
                    this.perPage = resp.data.data.per_page;
                    this.saved = false;
                    // this.status = true;
                    });
              return;
            }
            runInAction(() => {
              this.loadCategory = false;
              this.errloadCategory = resp?.data?.message;
            });
          })
          .catch((error) => {
            runInAction(() => {
                this.loadCategory = false;
                if(!error.response){
                    this.errloadCategory = error?.message;
                    toast.error(error?.message);
                }
                this.errloadCategory = error?.response?.data?.message;
                toast.error(error?.response?.data?.message);
            })
            return;
        });
    };


};


export const CategoryStoreContext = createContext(new CategoryStore());
export const useCategoryStore = () => useContext(CategoryStoreContext);