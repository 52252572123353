import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { useProductStore,ProductStore,ProductStoreContext } from "./store/ProductStore";
import { useEffect, useState } from "react";
import { MdProductionQuantityLimits } from "react-icons/md";

const CategoryProductManagement = () => {
    const { id } = useParams();
    const store = new ProductStore({
      isloading:false,
      editloading:false,
      categoryproduct:true,
      categoryid: id,
      page:1
    });
    return(
      <ProductStoreContext.Provider value={store}>
        <Screen/>
      </ProductStoreContext.Provider>
    )
  }
  
  export default CategoryProductManagement;

  const Screen = observer(()=>{
    const {
      products,
      loadproduct,
      nolist,
      getFilterProducts,
      statusChange,
      getstatusid,
    } = useProductStore();

    
    const goBack = () => {
      window.history.back();
    };

    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4 ">
          <p className=" text-2xl font-semibold text-moredarkgreen">Product Management</p>
            
        </div>
        <div>
            <Link onClick={goBack} className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
            </div>
       </div>
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Price</th>
                <th>Category</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-sm relative">
            {loadproduct && 
              <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6"></td>
              </tr>
              </>
              }

            {products && products?.map((data,index)=> (
              <tr key={index}>
                <td>{index+1}</td>
                <td className=" capitalize">{data?.name != null ? data?.name : "---" }</td>
                <td className=" capitalize">${data?.price}</td>
                <td className=" capitalize">{data?.category?.name}</td>
                <th>
                    <label
                      title="Change Status"
                      htmlFor="my-modal-5"
                      className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                      onClick={(e) => {
                        getstatusid(data?.id);
                      }}
                    >
                      {data.status ? "Active" : "Inactive"}
                    </label>
                  </th>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                      <button title="Edit">
                        <Link to={`/productmanagement/${data?.id}`}>
                            <RxPencil1 />
                        </Link>
                      </button>
                    </div>
                  </td>
                </tr>
             ))}
             
            {nolist && 
              <tr>
                <td colSpan={6} className="my-10 text-center">
                  <MdProductionQuantityLimits className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No Product Found</p>
                </td>
              </tr>
            }
            </tbody>
        </table>
        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })