/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdOutlineClose, MdOutlineCheck, MdOutlineRefresh, MdOutlineSearch, MdAdd, MdCancel } from "react-icons/md";
import { Input, Button } from "react-daisyui"
import { useProductStore, ProductStore, ProductStoreContext } from "./store/ProductStore";
import { observer } from "mobx-react";

const AddProductManagement = observer(() => {
    const store = new ProductStore({
        isloading: true,
    });
    return (
        <ProductStoreContext.Provider value={store}>
            <Screen />
        </ProductStoreContext.Provider>
    )
})

export default AddProductManagement;

const Screen = observer(() => {

    const { id } = useParams();
    const [formValues, setFormValues] = useState([]);
    const [imageLoopUrl, setImageLoopUrl] = useState([]);
    const [ProductName, setProductName] = useState([]);

    const [numTimesClicked, setNumTimesClicked] = useState(0);
    const [entries, setEntries] = useState([]);

    const handleAddMoreClick = () => {
        setEntries(prevEntries => [...prevEntries, { image: null, status: "" }]);
    };




    const handleRemoveClick = (index) => {
        setImageLoopUrl((prevUrls) => prevUrls.filter((_, i) => i !== index));
        setEntries(prevEntries => {
            const updatedEntries = [...prevEntries];
            updatedEntries.splice(index, 1);
            return updatedEntries;
        });
    };

    const {
        saved,
        image,
        audio,
        imageurl,
        loadproduct,
        category,
        name,
        price,
        status,
        description,
        Categorylist,
        Producttypelist,
        ProductType,
        bulkUpload,
        addproduct,
        getCategoryList,
        filetype,
        setclear,
        setname,
        setProductType,
        setprice,
        setdescription,
        setstatus,
        setimage,
        setcategory,
        setcheckbox,
        setbulkData,
        SethandelLoopName,
        handelLoopName,
        checkbox,
        addloadproduct, 
        addedloadproduct,
        bulkloadproduct
       
    } = useProductStore();



    const navigate = useNavigate();
    useEffect(() => {
        if (saved) {
            navigate("../productmanagement");
        }
    }, [saved, loadproduct]);

    const handleCheckboxChange = (e) => {
        const value = e.target.checked ? 1 : 0;
        setcheckbox(value);
    };
    //   useEffect(() => {
    //     getCategoryList()
    // },[])
    function clearFileInput() {
        var fileInput = document.getElementById('myFileInput');
        if (fileInput) {
            fileInput.value = ''; // Clear the value
        }
    }

    const handleBulkClick = () => {
        setEntries([...entries, { image: null, status: "" }]);
        // Assuming `image` and `name` are the state variables holding the current values of the fields

        setNumTimesClicked(numTimesClicked + 1);
    };

    const handleEntryChange = (index, field, value) => {
        setEntries(prevEntries => {
            if (index < prevEntries.length) {
                const updatedEntries = [...prevEntries];
                updatedEntries[index][field] = value;
                return updatedEntries;
            }
            else {
                // Add a new entry to the array if it doesn't exist yet (e.g., when name input is changed)

                return [...prevEntries, { [field]: value }];
            }
        });
    };
    const setImageLoop = (text, type, index) => {
        const fileType = type;
        if (text && fileType.startsWith('image/')) {
            setImageLoopUrl((prevUrls) => {
                const updatedUrls = [...prevUrls];
                updatedUrls[index] = URL.createObjectURL(text[0]);
                return updatedUrls;
            });
        }
    };
    useEffect(() => {
        if (ProductType == "1" || ProductType == "2") {
            setEntries([]);
        }
    }, [ProductType])

    if (entries) {
        setbulkData(entries);
    }
    return (
        <>
            <div className='flex justify-between'>
                <div className=" mb-4">
                    <p className=" text-2xl font-semibold text-moredarkgreen">Add Product</p>
                    {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
                </div>
                <div>
                    <Link to="/productmanagement" className="btn btn-sm bg-darkgreen text-yellow text-sm hover:bg-yellow hover:text-darkgreen ">Back</Link>
                </div>
            </div>
            <div className="card bg-base-100 shadow-md p-6 mt-3 mb-10">
                <form onSubmit={(e) => { ProductType == "3" || ProductType == "4" ? bulkUpload() : addproduct(id); e.preventDefault() }}>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">
                        <div>
                            <label htmlFor="ProductType" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Product Type<span className="text-[#f00]">&nbsp;*</span></label>
                            <select
                                required
                                id="ProductType"
                                name="ProductType"
                                defaultValue=""
                                onChange={(e) => {
                                    const selectedOption = e.target.options[e.target.selectedIndex];
                                    const selectedValue = selectedOption.value;
                                    const selectedName = selectedOption.text;
                                    setProductName(selectedName)
                                    setProductType(selectedValue);
                                    clearFileInput();
                                    setclear();
                                    getCategoryList(selectedValue);
                                    // Now you can use 'selectedName' to access the name of the selected option.
                                }}

                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" hidden className="">Select Product Type</option>
                                {Producttypelist && Producttypelist.map((res, index) => (
                                    <option key={index} value={res?.id}>{res?.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="Category" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Category<span className="text-[#f00]">&nbsp;*</span></label>
                            <select
                                required
                                id="Category"
                                disabled={!ProductType ? true : false}
                                name="Category"
                                defaultValue=""
                                onChange={(e) => { setcategory(e.target.value) }}
                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                <option value="" hidden className="">Select Category</option>
                                {Categorylist && Categorylist.map((res, index) => (
                                    <option key={index} value={res?.id}>{res?.name}</option>
                                ))}
                            </select>
                        </div>
                        {(ProductType !== "" && (ProductType !== "3" && ProductType !== "4")) && <div>
                            <label htmlFor="name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Name<span className="text-[#f00]">&nbsp;*</span></label>
                            <Input
                                required
                                name="name"
                                placeholder="Name"
                                id="name"
                                type="text"
                                onChange={(e) => { setname(e.target.value) }}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>}

                        {(ProductType !== "" && (ProductType !== "3" && ProductType !== "4")) && <div>
                            <label htmlFor="Price" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Price<span className="text-[#f00]">&nbsp;*</span></label>
                            <Input
                                required={ProductType == "1" || ProductType == "2" ? true : false}
                                name="Price"
                                placeholder="Price"
                                min="0.1"
                                title="Value must be greater than 0.1"
                                step="any"
                                id="name"
                                type="number"
                                onChange={(e) => { setprice(e.target.value) }}
                                autoComplete="off"
                                className="focus:outline-0 w-full text-sm font-medium rounded-md"
                            />
                        </div>}

                        {(ProductType == "2" || ProductType == "1") &&
                            <div>
                                <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                                <select
                                    required
                                    id="Status"
                                    name="Status"
                                    defaultValue=""
                                    value={entries[0]?.status || ''}
                                    onChange={(e) => { handleEntryChange(0, 'status', e.target.value); setstatus(e.target.value) }}
                                    className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                    <option value="" hidden className="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive </option>
                                </select>
                            </div>
                        }


                        <div>
                            <label htmlFor="Description" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
                            <textarea
                                required={ProductType == "1" || ProductType == "2" ? true : false}
                                name="Description"
                                placeholder="Description"
                                id="Description"
                                value={entries[0]?.description || ''}
                                onChange={(e) => { handleEntryChange(0, 'description', e.target.value); setdescription(e.target.value) }}
                                autoComplete="off"
                                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                            >
                            </textarea>
                        </div>
                        {(ProductType == "2" || ProductType == "1") &&
                            <div>
                                <label htmlFor="image" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">File<span className="text-[#f00]">&nbsp;*</span></label>

                                <Input
                                    name="image1"
                                    required
                                    placeholder="Image"
                                    id="myFileInput1"
                                    accept={ProductType == "2" ? "audio/*" : ".jpg, .png"}
                                    // defaultValue=""
                                    // onChange={(e) => handleImageChange(0, e.target.files[0])}
                                    // value={entries[0]?.image || ''}
                                    onChange={(e) => { setimage(e.target.files, e.target.files[0].name.split('.').pop().toLowerCase()); handleEntryChange(0, 'image', e.target.files[0]) }}
                                    type="file"
                                    autoComplete="off"
                                    className="focus:outline-0 w-full text-sm font-medium rounded-md col-span-2"
                                />
                                {filetype !== "mp3" && filetype !== "webm" ? <img className="w-[50%]" src={imageurl} /> : <audio controls src={audio}></audio>}
                            </div>
                        }

                        {/* <div>
                            <label htmlFor="checkbox" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Popular Product</label>
                            <div className=" grid grid-cols-3">
                            <Input
                                name="checkbox"
                                id="checkbox"
                                // defaultValue=""
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                autoComplete="off"
                                className="focus:outline-0 w-5 h-5 text-sm font-medium rounded-md "
                            />
                            </div>
                        </div> */}


                    </div>
                    {ProductType == "3" || ProductType == "4" ?

                        <>
                            <div className=" mb-4 mt-11">
                                <p className=" text-2xl font-semibold text-moredarkgreen">Add {ProductName}</p>
                                {/* <p className=" opacity-80 text-sm">Home /City/ Edit</p> */}
                            </div>


                            <div className="flex flex-row gap-9">
                                <div className="flex flex-col">

                                    <label htmlFor="image" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">File<span className="text-[#f00]">&nbsp;*</span></label>

                                    <Input
                                        name="image1"
                                        required
                                        placeholder="Image"
                                        id="myFileInput1"
                                        accept={ProductType == "2" ? "audio/*" : ".jpg, .png"}
                                        // defaultValue=""
                                        // onChange={(e) => handleImageChange(0, e.target.files[0])}
                                        // value={entries[0]?.image || ''}
                                        onChange={(e) => { setimage(e.target.files, e.target.files[0].name.split('.').pop().toLowerCase()); handleEntryChange(0, 'image', e.target.files[0]) }}
                                        type="file"
                                        autoComplete="off"
                                        className="focus:outline-0  text-sm font-medium rounded-md col-span-2"
                                    />
                                    {filetype !== "mp3" && filetype !== "webm" ? <img className="w-[50%]" src={imageurl} /> : <audio controls src={audio}></audio>}

                                </div>
                                <div className="w-[32%]">
                                    <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                                    <select
                                        required
                                        id="Status"
                                        name="Status"
                                        defaultValue=""
                                        value={entries[0]?.status || ''}
                                        onChange={(e) => { handleEntryChange(0, 'status', e.target.value); setstatus(e.target.value) }}
                                        className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                        <option value="" hidden className="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive </option>
                                    </select>
                                </div>
                            </div>

                        </>


                        : null}                                    {/* Button to add more entries */}
                    {ProductType != "1" || ProductType != "2" ? entries.map((entry, index) => (
                        index > 0 && ( // Hide input fields for first entry (index 0)
                            <div className={`card bg-base-100  mt-3 mb-10  `} key={index}>

                                <>
                                    <div className="grid lg:grid-cols-3 md:grid-cols-2 md:gap-8 gap-4">


                                        {/* <div>
    <label htmlFor="name" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Name<span className="text-[#f00]">&nbsp;*</span></label>
    <Input
required
name="name"
placeholder="Name"
id="name"
type="text"
value={entry?.name}
onChange={(e) => handleEntryChange(index, 'name', e.target.value)}

// onChange={(e) => handleNameChange(index + 1, e.target.value)}
onChangeCapture={(e)=> SethandelLoopName(e.target.value) }
autoComplete="off"
className="focus:outline-0 w-full text-sm font-medium rounded-md"
/>
</div> */}

                                        {/* <div>
                            <label htmlFor="Description" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Description<span className="text-[#f00]">&nbsp;*</span></label>
                            <textarea
                                required
                                name="Description"
                                placeholder="Description"
                                id="Description"
                                type="text"
                                value={entry?.description}
                                onChange={(e)=> {handleEntryChange(index, 'description', e.target.value);}}
                                autoComplete="off"
                                className="input focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 input-bordered"
                            >
                            </textarea>
                        </div> */}

                                        {(ProductType == 3 || ProductType == 4) &&

                                            <div>
                                                <label htmlFor="image" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">File<span className="text-[#f00]">&nbsp;*</span></label>
                                                <Input
                                                    name="image"
                                                    required
                                                    placeholder="Image"
                                                    id="myFileInput"

                                                    accept={ProductType == "2" ? "audio/*" : ".jpg, .png"}
                                                    onChange={(e) => {
                                                        handleEntryChange(index, 'image', e.target.files[0]);
                                                        setImageLoop(e.target.files, e.target.files[0].type, index);
                                                    }}
                                                    //  onChange={(e)=> {setimage(e.target.files,e.target.files[0].name.split('.').pop().toLowerCase())}}
                                                    type="file"
                                                    autoComplete="off"
                                                    className="focus:outline-0 w-full text-sm font-medium rounded-md col-span-2"
                                                />
                                                {imageLoopUrl
                                                    .filter((_, i) => i === index) // Filter the images for the current index
                                                    .map((image, imageIndex) => (
                                                        <div key={imageIndex}>
                                                            <img className="w-[50%] " src={image} alt={`Image ${index}`} />
                                                        </div>
                                                    ))}
                                            </div>
                                        }


                                        <div>
                                            <label htmlFor="Status" className="block text-sm font-semibold text-zinc-700 dark:text-stone-400 mb-2">Status<span className="text-[#f00]">&nbsp;*</span></label>
                                            <select
                                                required
                                                id="Status"
                                                name="Status"
                                                defaultValue=""
                                                value={entry?.status}
                                                //  onChange={(e) => handleStatusChange(index + 1, e.target.value)}
                                                onChange={(e) => handleEntryChange(index, 'status', e.target.value)}

                                                className={`select focus:outline-0 w-full text-sm font-medium rounded-md focus:outline-offset-0 select-bordered`}>
                                                <option value="" hidden className="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive </option>
                                            </select>
                                        </div>






                                    </div>
                                    {/* Button to add more entries */}
                                    <div className="lg:mt-3 mt-4 flex items-center mb-5 mr-5 md:justify-end justify-between">
                                        <label
                                            className="btn-sm items-center flex justify-center rounded-full bg-[#e87b7b] text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
                                            onClick={() => handleRemoveClick(index)}
                                        >
                                            <MdCancel className="text-xl" />
                                        </label>
                                    </div>
                                </>
                            </div>
                        )


                    )) : null}

                    {ProductType == "3" || ProductType == "4" ? <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                            {name != "" ? <label
                                className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
                                onClick={() => { handleAddMoreClick(); handleBulkClick() }}
                            >
                                <MdAdd className="mr-1" />Add More
                            </label> :
                                <button
                                    className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff] ml-4"
                                    onClick={() => { handleAddMoreClick(); handleBulkClick() }}
                                >
                                    <MdAdd className="mr-1" />Add More
                                </button>}

                        </div>
                    </div> : null}





                    <div className="lg:mt-3 mt-4 flex items-center md:justify-end justify-between">
                        <Link to="/productmanagement" className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen "><MdOutlineClose className="mr-1" />Cancel</Link>

                        { addedloadproduct || bulkloadproduct ? 
                       
                         <button 
                        className="btn btn-md opacity-80 bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4"
                          type="button"
                        //  onClick={()=> changeStep("4")}
                        >
                        <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                          Loading..
                        </button>
                         :
                         <button type="submit" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]   ml-4" ><MdOutlineCheck className="mr-1" />Add Product</button>
                        
                         }
                    </div>
                </form>
            </div>

        </>
    )
})








